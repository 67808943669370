import React from "react";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import List from "../../components/custom-widgets/list";
import Button from "../../components/custom-widgets/button";
import FaqAccordion from "../../components/faq/faq-accordion";
import MktoForm from "../../components/mkto-form/mkto-form";

//helper functions
import showMktoForm from "../../helpers/showMktoForm";

const LIBORTransition = () => {
  const title = "LIBOR to SOFR Transition: What You Need To Know",
    description =
      "Learn what you need to know about WaFd Bank's LIBOR to SOFR Transition, FAQs for commercial borrowers, and the changing of interest rate benchmarks.";
  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/libor-transition"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Libor Transition"
    }
  ];

  const objectivesARCCData = {
    greenCircleCheckmark: true,
    accountName: "arcc-objective",
    items: [
      {
        id: 1,
        text: "Identify alternative reference rates and determine whether identified alternative rates are compliant with the IOSCO's Principals for Financial Benchmarks."
      },
      {
        id: 2,
        text: "Identify best practices for contract robustness to ensure contracts are resilient to the possible cessation or material alteration of existing of new benchmarks."
      },
      {
        id: 3,
        text: "Develop an adoption plan to outline the necessary steps so that market participants could make the adoption more successful."
      },
      {
        id: 4,
        text: "Create a timeline and an implementation plan with metrics of success."
      }
    ]
  };

  const transitionChartData = [
    {
      year: "2020",
      monthlyDetails: [
        {
          date: "October 23",
          text: "ISDA publishes updated protocol"
        }
      ]
    },
    {
      year: "2021",
      monthlyDetails: [
        {
          date: "January 25",
          text: "ISDA Protocol Amendments and updated 2006 ISDA Definitions with fallbacks became effective"
        },
        {
          date: "June 30",
          text: "Cessation of remaining Libor Tenors"
        },
        {
          date: "July 29",
          text: "ARRC recommends a forward-thinking Term SOFR reference Rate"
        },
        {
          date: "December 31",
          text: "Cessation of week 1 & 2 month LIBOR, final deadline for LIBOR Originations"
        }
      ]
    },
    {
      year: "2023",
      monthlyDetails: [
        {
          date: "June 30",
          text: "Cessation of remaining LIBOR Loans"
        },
        {
          date: "July 1",
          text: "Term SOFR Replacement Index In Use"
        }
      ]
    }
  ];

  const faqAccordionData = {
    id: "libor-faq-accordion",
    title: "FAQs",
    faqCategoryNames: ["LIBOR"]
  };

  return (
    <SecondaryLanding>
      <Breadcrumb data={breadcrumbData} />
      <SEO {...seoData} />
      <MktoForm />
      <section className="container">
        <h1 id="libor-page-header">What You Need to Know About LIBOR Transition to SOFR</h1>
        <div className="row my-4">
          <div className="col-md-6 mb-4 mb-md-0">
            <h2 className="text-success">What you need to know</h2>
            <p>
              <strong>Background</strong>
              <br />
              On July 29th 2021, the Alternative Reference Rates Committee (ARRC) announced that it is formally
              recommending the CME Group's forward-looking Secured Overnight Financing Rate term rates (TERM SOFR) as
              the index to replace the sunsetting LIBOR (London Interbank Offered Rate). These rates are officially
              published on the{" "}
              <a href="https://www.cmegroup.com/" target="_blank" rel="noopener noreferrer">
                CME website
              </a>
              . WaFd Bank launched TERM SOFR loan products on January 1, 2022. Similar to LIBOR, TERM SOFR is available
              to WaFd Bank borrowers.
            </p>
            <p>
              <strong>What is the Alternative Reference Rates Committee (ARRC)?</strong>
              <br />A group of market participants organized by the Federal Reserve and New York Fed to help ensure a
              successful transition from Libor to a more robust reference rate.
            </p>
            <p>
              <strong>Objectives of ARRC:</strong>
            </p>
            <List {...objectivesARCCData} />
            <Button
              id="libot-contact-us-form"
              type="button"
              text="Contact Us"
              class="btn-primary btn-block w-sm-auto"
              showIcon={false}
              onClick={() => showMktoForm(1318)}
            />
          </div>
          <div className="col-md-6">
            <div className="bg-info p-3 text-white border-radius-12">
              {transitionChartData.map((data, index) => (
                <div className="mb-3" key={index}>
                  <h2 id={`year-${data.year}`}>{data.year}</h2>
                  {data.monthlyDetails.map((monthlyData, index) => (
                    <p className="ml-3" key={index}>
                      <strong>{monthlyData.date}</strong>
                      <br />
                      {monthlyData.text}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default LIBORTransition;
